import React, { useState, useEffect } from "react";
import apiAuth from "../../customs/axiosCustom";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ButtonGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { useAuth } from "../../customs/authService";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.background.default,
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const SemesterList = () => {
  const { t, currentSemester, setCurrentSemester, handleCurrentSemester } = useAuth();
  const [semesters, setSemesters] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [labs, setLabs] = useState([]);
  const [newSemester, setNewSemester] = useState({
    semesterId: "",
    semesterName: "",
    startDate: "",
    endDate: "",
  });
  const [editingSemester, setEditingSemester] = useState(null);
  const [dialogReport, setDialogReport] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState({});

  const fetchSemesters = async () => {
    showLoading();
    try {
      const response = await apiAuth.get("/Semester");
      setSemesters(response.data);
    } catch (err) {
      toast.error("Error fetching semesters.");
    } finally {
      hideLoading();
    }
  };

  const fetchLaboratories = async () => {
    try {
      const response = await apiAuth.get("/Semester/laboratories");
      setLabs(response.data);
    } catch (err) {
      toast.error("Error fetching semesters.");
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    fetchSemesters();
    fetchLaboratories();
  }, []);

  const handleDialogOpen = (semester = null) => {
    setEditingSemester(semester);
    setNewSemester(
      semester
        ? {
            semesterId: semester.semesterId,
            semesterName: semester.semesterName,
            startDate: semester.startDate.slice(0, 10),
            endDate: semester.endDate.slice(0, 10),
          }
        : {
            semesterName: "",
            startDate: "",
            endDate: "",
          }
    );
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditingSemester(null);
  };

  const handleReportDialogOpen = (semester) => {
    setDialogReport(true);
    setSelectedSemester(semester);
  };

  const handleReportDialogClose = () => {
    setDialogReport(false);
    setSelectedSemester({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSemester((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addSemester = async () => {
    try {
      await apiAuth.put(
        newSemester?.semesterId
          ? `/Semester/${newSemester?.semesterId}`
          : `/Semester`,
        newSemester
      );
      if (newSemester?.semesterId === currentSemester.semesterId) {
        setCurrentSemester(newSemester);
      }
      if (newSemester?.semesterId) {
        toast.success(t("reportV.updateSemester"));
      } else {
        toast.success(t("reportV.sAddSucc"));
      }
      fetchSemesters();
      handleCurrentSemester();
      handleDialogClose();
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.message;
        switch (err.response.status) {
          case 400:
            toast.error(errorMessage);
            break;
          case 409:
            toast.error(errorMessage);
            break;
          case 422:
            toast.error(errorMessage);
            break;
          case 500:
            toast.error(errorMessage);
            break;
          default:
            toast.error(errorMessage);
            break;
        }
      } else {
        toast.error(
          "An unexpected error occurred. Please check your connection."
        );
      }
    }
  };

  const handleAddOrUpdateSemester = () => {
    addSemester();
  };

  const handleExportExcel = async (lab) => {
    showLoading();
    try {
      const response = await apiAuth.get(
        `/Report/export/excel/${lab.id}/${selectedSemester.semesterId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      let filename = `Final-Report-${lab.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.Message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.Message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.Message || "Server error. Please try again later.");
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleExportExcelMiddle = async (lab) => {
    showLoading();
    try {
      const response = await apiAuth.get(
        `/Report/exportMiddle/excel/${lab.id}/${selectedSemester.semesterId}`,
        { responseType: "blob" }
      );
      let filename = `Middle-Report-${lab.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // Sử dụng tên file từ API
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.Message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.Message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.Message || "Server error. Please try again later.");
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      hideLoading();
    }
  };

  const handleDeleteSemester = async (id) => {
    if (window.confirm("Are you sure you want to delete this semester?")) {
      try {
        const response = await apiAuth.delete(`/Semester/${id}`);
        if (response.status === 204) {
          // No Content (204) indicates successful deletion
          toast.success("Semester deleted successfully!");
          await fetchSemesters(); // Ensure that fetchSemesters() is called after deletion
        } else if (response.status === 409) {
          // Conflict (409) when semester has started or has associated interns
          toast.error(
            response.data?.message || "Conflict: Cannot delete this semester."
          );
        } else if (response.status === 404) {
          // Not Found (404) when semester is not found
          toast.error(
            response.data?.message || "Not Found: Semester not found."
          );
        } else if (response.status === 500) {
          // Internal Server Error (500) for server issues
          toast.error(
            response.data?.message || "Server Error: Error deleting semester."
          );
        } else {
          // Handle unexpected status codes
          toast.error(response.data?.message || "Error deleting semester.");
        }
      } catch (err) {
        // Handle network or unexpected errors
        toast.error(err.response?.data?.message || "Error deleting semester.");
      }
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        {t("reportV.listOfSemesters")}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mb: 2 }}
        onClick={() => handleDialogOpen()}
      >
        {t("reportV.addSemester")}
      </Button>
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("reportV.semesterName")}</StyledTableCell>
                <StyledTableCell>{t("reportV.sd")}</StyledTableCell>
                <StyledTableCell>{t("reportV.ed")}</StyledTableCell>
                <StyledTableCell>{t("reportV.ac")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {semesters.map((semester) => (
                <StyledTableRow key={semester.semesterId}>
                  <TableCell>{semester.semesterName}</TableCell>
                  <TableCell>{semester.startDate.slice(0, 10)}</TableCell>
                  <TableCell>{semester.endDate.slice(0, 10)}</TableCell>
                  <TableCell>
                    <ButtonGroup>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleDialogOpen(semester)}
                      >
                        {t("reportV.edit")}
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() =>
                          handleDeleteSemester(semester.semesterId)
                        }
                      >
                        {t("reportV.delete")}
                      </Button>
                    </ButtonGroup>
                    <Button
                      sx={{ marginX: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleReportDialogOpen(semester)}
                    >
                      Reports
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{t("reportV.addSemester")}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="semesterName"
              label={t("reportV.semesterName")}
              type="text"
              fullWidth
              variant="outlined"
              value={newSemester.semesterName}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="startDate"
              label={t("reportV.sd")}
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={newSemester.startDate}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="endDate"
              label={t("reportV.ed")}
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={newSemester.endDate}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("userProfile.btnCancel")}
            </Button>
            <Button onClick={handleAddOrUpdateSemester} color="primary">
              {t("reportV.btnAdd")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={dialogReport} onClose={handleReportDialogClose} fullWidth maxWidth={"md"}>
          <DialogTitle>{t("reportV.listOfLab")}</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("reportV.labName")}</TableCell>
                    <TableCell>{t("reportV.ac")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {labs.length > 0 ? (
                    labs.map((lab) => (
                      <TableRow key={`lab-${lab.id}`}>
                        <TableCell>{lab.labName}</TableCell>
                        <TableCell sx={{display: "flex"}}>
                          <Button
                            onClick={() => handleExportExcelMiddle(lab)}
                            variant="outlined"
                            color="primary"
                          >
                            {t("reportV.exporttoExcel1")}
                          </Button>
                          <Button
                            sx={{marginLeft: 2}}
                            onClick={() => handleExportExcel(lab)}
                            variant="outlined"
                            color="success"
                          >
                            {t("reportV.exporttoExcel")}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Typography>{t("reportV.noLab")}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReportDialogClose} color="primary">
              {t("reportV.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </Container>
  );
};

export default SemesterList;
