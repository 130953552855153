import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Collapse,
  IconButton,
  Chip,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const SemesterList = () => {
  const { auth } = useAuth();
  const [semesters, setSemesters] = useState([]);
  const [semester, setSemester] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isActive, setIsActive] = useState(null); // Filter by active status
  const [semesterId, setSemesterId] = useState(""); // Filter by semester
  const [totalItems, setTotalItems] = useState(0);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [internsLoading, setInternsLoading] = useState(false);
  const [interns, setInterns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [lab, setLab] = useState(null);
  const navigate = useNavigate();
  const { t } = useAuth();

  // Khởi tạo mảng trạng thái mở/tắt cho từng hàng, mặc định là false
  const [openRows, setOpenRows] = useState({});

  // Hàm xử lý mở/tắt cho từng hàng dựa trên internId
  const handleToggleRow = (internId) => {
    setOpenRows((prev) => ({
      ...prev,
      [internId]: !prev[internId], // Đảo ngược trạng thái của hàng tương ứng với internId
    }));
  };
  const fetchSemesters = async () => {
    try {
      const response = await apiAuth.get("/Semester");
      setSemesters(response.data);
      setError(null);
    } catch (err) {
      toast.error("Error fetching semesters.");
    }
  };

  const fetchReports = async () => {
    try {
      const response = await apiAuth.get("/Report", {
        params: {
          keyword: searchKeyword,
          semesterId: semesterId,
          pageNumber: page + 1,
          pageSize: rowsPerPage,
        },
      });
      setReports(response.data?.reportsByIntern || []);
      setTotalItems(response.data?.totalItemCount)
      setError(null);
    } catch (err) {
      toast.error("Error fetching reports.");
    }
  };

  const fetchLab = async () => {
    try {
      const response = await apiAuth.get(`/Semester/lab/${auth.user.labId}`);
      setLab(response.data);
    } catch (err) {
      toast.error("Error fetching reports.");
    }
  };

  useEffect(() => {
    try {
      showLoading();
      fetchSemesters();
      fetchLab();
    } catch (err) {
      toast.error("Error fetching");
    } finally {
      hideLoading();
    }
  }, []);

  const handleViewSemesterClick = async (semester) => {
    setInternsLoading(true);
    setError(null);

    try {
      const response = await apiAuth.get(
        `/Semester/${semester.semesterId}/interns`
      );

      setSemester(semester);

      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setInterns(response.data);
        } else {
          toast.info(t("reportV.errors1.409"));
          setInterns([]);
        }
      } else {
        toast.error(t("reportV.sUnex"));
      }
      setSelectedSemester(semester); // Set selectedSemester to the current semesterId
      setOpen(true);
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.message;

        switch (err.response.status) {
          case 400:
            toast.error(t("reportV.errors1.400"));
            break;
          case 409:
            toast.error(t("reportV.errors1.409"));
            break;
          case 500:
            toast.error(
              errorMessage || "Server error. Please try again later."
            );
            break;
          default:
            toast.error(errorMessage);
            break;
        }
      } else {
        toast.error(
          "An unexpected error occurred. Please check your connection."
        );
      }
    } finally {
      setInternsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSemester({});
    setInterns([]);
  };

  const handleAddReportClick = (intern) => {
    toast.info("Navigating to Add Report page");
    navigate(`/add-report`, {
      state: { internData: intern },
    });
  };

  const handleEditReportClick = (intern, report) => {
    navigate(`/add-report`, {
      state: { internData: intern, report: report },
    });
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const response = await apiAuth.get(
        `/Report/export/excel/${lab?.id}/${selectedSemester.semesterId}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let filename = `Final-Report-${lab?.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.message || t("systemError"));
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcelMiddle = async () => {
    setLoading(true); // Set loading state to true when starting export
    try {
      const response = await apiAuth.get(
        `/Report/exportMiddle/excel/${lab?.id}/${selectedSemester.semesterId}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let filename = `Middle-Report-${lab?.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.Message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.Message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.Message || "Server error. Please try again later.");
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false); // Set loading state to false after export is complete
    }
  };

  const handleDeleteReport = (reportId) => {
    if (window.confirm(t("reportV.sDelete"))) {
      apiAuth
        .delete(`/Report/${reportId}`)
        .then(() => {
          // Update local state to remove the deleted report
          fetchReports();
          toast.success(t("reportV.sDeleteSuc"));
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with an error
            const status = error.response.status;
            const message =
              error.response.data.message ||
              "Error deleting report. Please try again later.";

            switch (status) {
              case 400:
                toast.error(`Bad Request: ${message}`);
                break;
              case 404:
                toast.error(`Not Found: ${message}`);
                break;
              case 500:
                toast.error(`${message}`);
                break;
              default:
                toast.error(`${message}`);
                break;
            }
          } else if (error.request) {
            // Request was made but no response received
            toast.error(
              "No response from server. Please check your network connection."
            );
          } else {
            // Something else went wrong
            toast.error("Error deleting report. Please try again later.");
          }
        });
    }
  };

  useEffect(() => {
    fetchReports();
  }, [page, rowsPerPage, semesterId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0);
    fetchReports()
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {t("reportV.listOfSemesters")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("reportV.semesterName")}</TableCell>
              <TableCell align="center">{t("reportV.sd")}</TableCell>
              <TableCell align="center">{t("reportV.ed")}</TableCell>
              <TableCell align="center">{t("reportV.ac")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {semesters.map((semester) => (
              <TableRow key={semester.semesterId}>
                <TableCell align="center">{semester.semesterName}</TableCell>
                <TableCell align="center">
                  {new Date(semester.startDate).toLocaleDateString("en-GB")}
                </TableCell>
                <TableCell align="center">
                  {new Date(semester.endDate).toLocaleDateString("en-GB")}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewSemesterClick(semester)}
                  >
                    {t("reportV.viewIntern")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{t("reportV.internsforSemester")}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("reportV.nameI")}</TableCell>
                  <TableCell>{t("reportV.email")}</TableCell>
                  <TableCell>{t("reportV.rollName")}</TableCell>
                  <TableCell>{t("reportV.ac")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interns.length > 0 ? (
                  interns.map((intern) => {
                    const semesterEnd = new Date(semester?.endDate);
                    return (
                      <TableRow key={intern.id}>
                        <TableCell>{intern.nameIntern}</TableCell>
                        <TableCell>{intern.email}</TableCell>
                        <TableCell>{intern.rollName}</TableCell>
                        <TableCell>
                          {(intern.reportCount <= 1 ||
                            new Date() <
                              semesterEnd.setDate(
                                semesterEnd.getDate() + 3
                              )) && (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAddReportClick(intern)}
                                style={{ marginLeft: "10px" }}
                              >
                                {t("report.addReport")}
                              </Button>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography>{t("reportV.noi")}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("reportV.close")}
          </Button>
          <Button onClick={() => handleExportExcelMiddle()} color="primary">
            {t("reportV.exporttoExcel1")}
          </Button>
          <Button onClick={() => handleExportExcel()} color="primary">
            {t("reportV.exporttoExcel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2} display="flex" alignItems="center" marginY={2}>
        <TextField
          label={t("reportV.searchKey")}
          variant="outlined"
          value={searchKeyword}
          fullWidth
          onChange={(e) => setSearchKeyword(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        <FormControl sx={{ mr: 2, minWidth: 150 }} variant="outlined">
          <Select
            value={semesterId}
            displayEmpty
            onChange={(e) => {
              const semesterName = e.target.value;
              setSemesterId(semesterName);
            }}
          >
            <MenuItem value="">All Semesters</MenuItem>
            {semesters.map((semester) => (
              <MenuItem key={semester.semesterId} value={semester.semesterId}>
                {semester.semesterName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
          size="medium"
          sx={{ width: 150 }}
        >
          {t("reportV.search")}
        </Button>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("reportV.rollName")}</TableCell>
              <TableCell>{t("reportV.nameI")}</TableCell>
              <TableCell>{t("reportV.email")}</TableCell>
              <TableCell>{t("reportV.nameM")}</TableCell>
              <TableCell>{t("reportV.semesterName")}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {t("reportV.listReport")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((intern) => (
              <React.Fragment key={intern.internId}>
                <TableRow>
                  <TableCell>{intern.rollName}</TableCell>
                  <TableCell>{intern.nameIntern}</TableCell>
                  <TableCell>{intern.email}</TableCell>
                  <TableCell>{intern.nameMentor}</TableCell>
                  <TableCell>{intern.semester.name}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleToggleRow(intern.internId)} // Kích hoạt toggle
                    >
                      {openRows[intern.internId] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>

                {/* Danh sách báo cáo ẩn/hiện */}
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={openRows[intern.internId]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box margin={1}>
                        <Table size="medium">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t("reportV.createdDate")}</TableCell>
                              <TableCell>{t("reportV.reportTitle")}</TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {t("report.majorSkill")}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {t("report.softSkill")}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {t("report.attitude")}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {t("reportV.finalResult")}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                {t("reportV.ac")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {intern.reports.map((rep) => {
                              const semesterEnd = new Date(
                                intern?.semester?.endDate
                              );
                              return (
                                <TableRow key={rep.reportId}>
                                  <TableCell>
                                    {new Date(rep.createdDate).toLocaleString(
                                      "en-GB"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {t(`reportV.reportType.${rep.reportTitle}`)}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {rep.majorSkill}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {rep.softSkill}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {rep.attitude}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {rep.finalResult}
                                  </TableCell>
                                  <TableCell sx={{ textAlign: "center" }}>
                                    {new Date() <
                                    semesterEnd.setDate(
                                      semesterEnd.getDate() + 3
                                    ) ? (
                                      <>
                                        <Button
                                          variant="contained"
                                          color="info"
                                          onClick={() =>
                                            handleEditReportClick(intern, rep)
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          {t("report.editReport")}
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color="error"
                                          onClick={() =>
                                            handleDeleteReport(rep.reportId)
                                          }
                                          style={{ marginLeft: "10px" }}
                                        >
                                          {t("report.delReport")}
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <Chip
                                          label={t(`report.semesterEnded`)}
                                          color="default"
                                        />
                                      </>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SemesterList;
