import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Box,
  Button,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAuth } from "../../customs/authService";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import apiAuth from "../../customs/axiosCustom";

export default function CustomDrawer({
  open,
  handleDrawerClose,
  openedMixin,
  closedMixin,
  drawerWidth,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerItems, t, isLogged, auth } = useAuth();
  const theme = useTheme();

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = async () => {
    try {
      const response = await apiAuth.get("/User/lab-info");
      const { user } = response.data;
      setUserInfo(user);
    } catch (error) {
      setUserInfo(null);
      console.error(t(error.response?.data.message));
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [auth]);

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "softblue",
          display: "flex",
          flexDirection: "column",
        },
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader>
        <Box>
          {isLogged() ? (
            <Tooltip
              title={
                <>
                  {userInfo?.labName ? `Lab Name: ${userInfo?.labName} - ` : ""}
                  Roles: {userInfo?.roles.join(", ")}
                  <br />
                  {userInfo?.mentor !== undefined
                    ? `Mentor: ${userInfo?.mentor}`
                    : ""}
                </>
              }
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.main",
                    marginX: 3,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: 200,
                  }}
                >
                  Welcome, {userInfo?.fullname}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Button href="/login">Log in</Button>
          )}
        </Box>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {drawerItems.map((item, index) => {
          const isActive = location.pathname === item.href;
          return (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              key={`drawerItem-${index}`}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor: isActive ? "rgba(0, 0, 0, 0.08)" : "inherit",
                }}
                onClick={() => navigate(item.href)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={t(`drawerItems.${item.primaryText}`)}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      {open && (
        <>
          <Divider />
          <Box
            sx={{
              p: 2,
              bgcolor: "background.default",
            }}
          >
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                whiteSpace: "pre-wrap", // Allows text to wrap
                overflowWrap: "break-word", // Ensures long words break properly
              }}
            >
              {t(`drawerItems.copyright`)}
            </Typography>
          </Box>
        </>
      )}
    </Drawer>
  );
}
