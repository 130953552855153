import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TableHead,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Box,
  AppBar,
  Toolbar,
  ButtonGroup,
} from "@mui/material";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ViewPDF from "../User/PDFviewer";
import Logo from "../../assets/images/LIM.svg";

const ImportCV = () => {
  const { signature } = useParams();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");
  const [initialPdf, setInitialPdf] = useState("");
  const [intern, setIntern] = useState(null);
  const api_url = process.env.REACT_APP_BE_API;
  const navigate = useNavigate();

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (acceptedFiles[0].size > maxSize) {
      toast.error(t("importCv.maxSize"));
      return;
    }

    if (selectedFile.type === "application/pdf") {
      setSelectedFile(selectedFile);
      setPdfUrl(URL.createObjectURL(selectedFile));
    } else {
      toast.error(t("importCv.onlyPdf"));
    }
  };

  const getInternInfo = async () => {
    try {
      const response = await axios(
        `${api_url}/Import/TakeCV?signature=${signature}`
      );
      if (response.data.cv !== "") {
        setInitialPdf(response.data.cv);
      }
      if (new Date(response.data.expiredAt) < new Date()) {
        toast.error(t("importCv.overTimeToUploadCv", {time: response.data.expiredAt}));
      }
      setIntern(response.data);
    } catch (err) {
      toast.error(t("importCv.noInternFound"));
    }
  };

  useEffect(() => {
    getInternInfo();
  }, []);

  const handleUploadCV = async (event) => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${api_url}/Import/pdf?signature=${signature}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(t(`importCv.${response.data.message}`));
      setSelectedFile(null);
    } catch (error) {
      const { data } = error.response;
      if (data?.message === "systemError") {
        toast.error(t(`${data?.message}`));
        return;
      }
      toast.error(t(`importCv.${data?.message}`, {time: intern?.expiredAt}));
    }
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "whitesmoke",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex" }}>
            <img src={Logo} alt="Logo" style={{ width: "23%", cursor: "pointer" }} onClick={() => navigate('/')}/>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid
        container
        justifyContent="center"
        bgcolor={"gray"}
        sx={{ minHeight: '100vh', pt: 7 }}
        gap={1}
      >
        <Grid item xs={6}>
          <Paper style={{ padding: "16px", marginTop: "16px" }}>
            <Typography variant="h5" gutterBottom>
              {t("importCv.components.importLabel")}
            </Typography>
            <DropPDF onDrop={handleDrop} />
            {selectedFile && (
              <Typography variant="h6">
                {selectedFile.name}
              </Typography>
            )}
            <br />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: "5px" }}
              onClick={handleUploadCV}
            >
              {t("importCv.components.importBtn")}
            </Button>

            <Button
              variant="contained"
              color="error"
              type="submit"
              sx={{ marginLeft: 2 }}
              style={{ marginTop: "5px" }}
              onClick={() => {
                setSelectedFile(null);
                setPdfUrl("");
              }}
              disabled={pdfUrl ? false : true}
            >
              {t("importCv.components.clearBtn")}
            </Button>
          </Paper>
          <Paper style={{ padding: "16px", marginTop: "16px" }}>
            <Typography variant="h6" gutterBottom>
              {t("importCv.components.internLabel")}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("importCv.intern.rollName")}</TableCell>
                    <TableCell>{t("importCv.intern.fullName")}</TableCell>
                    <TableCell>{t("importCv.intern.dob")}</TableCell>
                    <TableCell>{t("importCv.intern.email")}</TableCell>
                    <TableCell>{t("importCv.intern.labName")}</TableCell>
                    <TableCell>{t("importCv.intern.expiredAt")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {intern ? (
                    <TableRow>
                      <TableCell>{intern.rollName}</TableCell>
                      <TableCell>{intern.fullName}</TableCell>
                      <TableCell>
                        {new Date(intern.dob).toLocaleDateString("en-GB")}
                      </TableCell>
                      <TableCell>{intern.email}</TableCell>
                      <TableCell>{intern.labName}</TableCell>
                      <TableCell
                        sx={{
                          color:
                            new Date() > new Date(intern.expiredAt)
                              ? "error.main"
                              : "inherit",
                        }}
                      >
                        {new Date(intern.expiredAt).toLocaleString("en-GB")}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{
                          color: "error.main",
                          textAlign: "center",
                        }}
                      >
                        {t("importCv.noInternFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper style={{ padding: "16px", marginTop: "16px" }}>
            <Typography variant="h6" gutterBottom>
              {t("importCv.components.preview")}
            </Typography>
            {pdfUrl || initialPdf ? (
              <Box
                sx={{
                  width: "auto",
                  height: "800px",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {<ViewPDF pdfUrl={!pdfUrl ? initialPdf : pdfUrl} />}
              </Box>
            ) : (
              <Paper
                style={{
                  boxShadow: 24,
                  padding: "16px",
                  marginTop: "16px",
                  height: "800px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  {t("importCv.components.noPreview")}
                </Typography>
              </Paper>
            )}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ImportCV;
