import React from "react";
import { useAuth } from "../../customs/authService";
import Home from "./Home";
import DashboardComponent from "./Dashboard";
import ImportCsvComponent from "../Import/ImportCsvComponent";
import { useNavigate } from "react-router-dom";

const DashboardAll = () => {
  const { auth } = useAuth();
  const navigate = useNavigate()

  const roles = auth.user.roles;
  return (
    <>
      {roles.includes("Intern") && <Home />}
      {roles.includes("Mentor") && (
        <DashboardComponent />
      )}
      {roles.includes("System Admin") && 
        <ImportCsvComponent />
      }
    </>
  );
};

export default DashboardAll;
