import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Toolbar,
  styled,
  ButtonGroup,
  Typography,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { useAuth } from "../../customs/authService";
import Logo from "../../assets/images/LIM.svg";
import { useNavigate } from "react-router-dom";
import { accountMenus } from "../../assets/configs/routes";
import Attendance from "../Attendance/Attendance";
import Notification from "./Notification";
import Account from "./Account";
import AutoBatchAttendanceToggle from "../Attendance/AutoBatchAttendanceToggle";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomAppBar = ({ open, handleDrawerOpen, isIntern }) => {
  const { logout, isLogged, t, currentSemester, auth, isTesting } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const navigate = useNavigate();

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const handleAccountClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorE2(null);
  };

  const handleAccountMenu = (path) => {
    handleAccountClose();
    navigate(path);
  };

  const handleNotificationMenu = (path) => {
    handleNotificationClose();
    navigate(path);
  };

  return (
    <AppBar
      position="fixed"
      open={open}
      sx={{
        backgroundColor: "whitesmoke",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          {isLogged() ? (
            <IconButton
              onClick={handleDrawerOpen}
              edge="start"
              size="small"
              sx={{
                ...(open
                  ? { display: "none" }
                  : {
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                      "&:active": {
                        backgroundColor: "transparent",
                      },
                    }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <img src={Logo} alt="Logo" style={{ width: "23%", cursor: "pointer" }} onClick={() => navigate('/')}/>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isLogged() ? (
            <>
              <Tooltip
                title={
                  currentSemester?.startDate && currentSemester?.endDate
                    ? t("currentSemester.period", {
                        startDate: new Date(
                          currentSemester?.startDate
                        ).toLocaleDateString("en-GB"),
                        endDate: new Date(
                          currentSemester?.endDate
                        ).toLocaleDateString("en-GB"),
                      })
                    : t("semesterWait")
                }
              >
                <Typography
                  sx={{
                    color: "primary.main",
                    verticalAlign: "middle",
                    marginX: 3,
                  }}
                >
                  {t("currentSemester.name", {
                    name: currentSemester?.semesterName ?? t("semesterWait"),
                  })}
                </Typography>
              </Tooltip>
              {auth?.user?.roles.includes("System Admin") && isTesting() && (
                <AutoBatchAttendanceToggle />
              )}
              {isIntern && <Attendance />}
              <Notification
                handleNotificationClick={handleNotificationClick}
                handleNotificationClose={handleNotificationClose}
                handleNotificationMenu={handleNotificationMenu}
                anchorEl={anchorEl}
              />
              <Account
                accountMenus={accountMenus}
                anchorE2={anchorE2}
                handleAccountClick={handleAccountClick}
                handleAccountClose={handleAccountClose}
                handleAccountMenu={handleAccountMenu}
                logout={logout}
              />
            </>
          ) : (
            <ButtonGroup variant="contained" aria-label="Basic button group">
              <Button
                onClick={() => navigate("/login")}
                variant="contained"
                color="info"
              >
                Log in
              </Button>
              <Button
                onClick={() => navigate("/admin/login")}
                variant="contained"
                color="info"
              >
                Log in (Admin)
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
