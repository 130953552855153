import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { OKRContext } from "../../context/OKRContext";
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Slider,
  Box,
  Collapse,
  InputLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import CommentIcon from "@mui/icons-material/Comment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CommentReplyDialog from "../Okr/CommentReplyDialog";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ObjectiveForm from "../Okr/ObjectiveForm";
import { useAuth } from "../../customs/authService";
import { format } from "date-fns";
import debounce from "lodash/debounce"; // Import debounce from lodash

const MainTableView = () => {
  const { t, auth } = useAuth();
  const [objectiveDialogOpen, setObjectiveDialogOpen] = useState(false);
  const [commentReplyDialogOpen, setCommentReplyDialogOpen] = useState(false);
  const [currentObjectiveId, setCurrentObjectiveId] = useState(null);
  const [currentKeyResultId, setCurrentKeyResultId] = useState(null);
  const [editingCell, setEditingCell] = useState(null);
  const [cellValue, setCellValue] = useState("");
  const [cellErrors, setCellErrors] = useState({});
  const [editingObjective, setEditingObjective] = useState({
    objectiveId: null,
    currentAttribute: null,
  });
  const [objectiveValue, setObjectiveValue] = useState({});
  const [objectiveErrors, setObjectiveErrors] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterPriority, setFilterPriority] = useState("");
  const [sortCriteria, setSortCriteria] = useState("status");
  const [sortKeyCriteria, setSortKeyCriteria] = useState("title");
  const [expandedObjectiveId, setExpandedObjectiveId] = useState(null);
  const [tempKeyResults, setTempKeyResults] = useState([]);
  const [unsavedObjectives, setUnsavedObjectives] = useState([]);
  const [unsavedKeyResults, setUnsavedKeyResults] = useState([]);
  const [comments, setComments] = useState([]);

  const {
    objectives,
    keyResults,
    addObjective,
    updateObjective,
    addKeyResult,
    updateKeyResult,
    deleteKeyResult,
    deleteObjective,
    teamMembers,
    teamId,
    fetchTeamMembers,
    fetchObjectives,
    fetchKeyResults,
    fetchComments,
    addComment,
    updateComment,
    deleteComment,
    allComments,
    setObjectives,
    updateObjectiveStatus,
    approveObjective,
  } = useContext(OKRContext);

  const isMentor = auth?.user?.roles.includes("Mentor");

  useEffect(() => {
    fetchObjectives();
    setExpandedObjectiveId(null);
  }, [teamId, auth]);

  const handleCheckboxChange = async (event, objectiveId) => {
    const isApprove = event.target.checked;
    try {
      await approveObjective(objectiveId);
      const msg = isApprove
        ? t(`okr.toast_approve_obj_succeed`)
        : t(`okr.toast_reject_obj_succeed`);

      toast.success(msg);
    } catch (err) {
      if (err.response.status === 404) {
        toast.error(`okr.toast_not_found`);
        return;
      }
      const msg = isApprove
        ? t(`okr.toast_approve_obj_failed`)
        : t(`okr.toast_reject_obj_failed`);
      toast.error(msg);
    }
  };

  useEffect(() => {
    if (expandedObjectiveId) {
      fetchTeamMembers();
      fetchKeyResults();
      setEditingCell({});
    }
  }, [expandedObjectiveId]);

  useEffect(() => {
    if (currentObjectiveId || currentKeyResultId) {
      const filteredComments = allComments.filter(
        (comment) =>
          (currentObjectiveId &&
            comment.reletedObjectId === currentObjectiveId &&
            comment.reletedObjectType === "Objective") ||
          (currentKeyResultId &&
            comment.reletedObjectId === currentKeyResultId &&
            comment.reletedObjectType === "KeyResult")
      );
      setComments(filteredComments);
    }
  }, [allComments, currentObjectiveId, currentKeyResultId]);

  const handleObjectiveSave = async (objective) => {
    const errors = validateObjective(objective);
    if (Object.keys(errors).length > 0) {
      setObjectiveErrors(errors);
    } else {
      objective.teamId = teamId;
      try {
        await addObjective(objective);
        toast.success(t("okr.toast_add_o_s"));
        setObjectiveDialogOpen(false);
      } catch (err) {
        toast.error(t("okr.toast_add_f"));
      }
    }
  };

  const validateObjective = (objective) => {
    const errors = {};
    if (!objective.title) errors.title = t("okr.error_title");
    if (!objective.description) errors.description = t("okr.error_description");
    if (!objective.startDate) errors.startDate = t("okr.error_startDate");
    if (!objective.endDate) errors.endDate = t("okr.error_endDate");
    if (objective.startDate && objective.endDate) {
      const startDate = new Date(objective.startDate);
      const endDate = new Date(objective.endDate);
      if (startDate >= endDate) {
        errors.endDate = t("okr.error_endBeforeStart");
      }
    }
    return errors;
  };

  const validateCell = (columnName, value, rowData) => {
    const errors = {};

    if (columnName === "title" && (!value || value.trim() === "")) {
      errors[columnName] = t("okr.error_title");
    } else if (columnName === "startDate" || columnName === "endDate") {
      if (!value) {
        errors[columnName] = t("okr.error_date_required");
      } else if (rowData && rowData.startDate && rowData.endDate) {
        const startDate = new Date(rowData.startDate);
        const endDate = new Date(rowData.endDate);

        if (startDate >= endDate) {
          // Only set this error on one field, e.g., the endDate field
          errors["endDate"] = t("okr.error_endBeforeStart");
        }
      }
    } else if (
      (columnName === "estimateManDay" || columnName === "actualManDay") &&
      (value === "" || isNaN(value) || value < 0)
    ) {
      errors[columnName] = t("okr.error_ManDay");
    }

    return errors;
  };

  const handleCommentsClick = async (keyResultId, objectiveId) => {
    setCurrentObjectiveId(objectiveId);
    setCurrentKeyResultId(keyResultId);
    setCommentReplyDialogOpen(true);

    try {
      await fetchComments(); // Ensure comments are fetched

      const relatedComments = allComments.filter(
        (comment) =>
          (comment.reletedObjectType === "Objective" &&
            comment.reletedObjectId === objectiveId) ||
          (comment.reletedObjectType === "KeyResult" &&
            comment.reletedObjectId === keyResultId)
      );
      setComments(relatedComments);
    } catch (error) {
      setComments([]);
    }
  };

  const handleCloseCommentDialog = () => {
    setCommentReplyDialogOpen(false);
    setCurrentObjectiveId(null);
    setCurrentKeyResultId(null);
  };

  const handleAddComment = async (
    newComment,
    mentionedUserIds,
    attachedFiles,
    parentCommentId = null
  ) => {
    try {
      await addComment(
        currentObjectiveId,
        currentKeyResultId,
        newComment,
        mentionedUserIds || [],
        attachedFiles || "",
        parentCommentId
      );
      await fetchComments();
      const relatedComments = allComments.filter(
        (comment) =>
          (comment.reletedObjectType === "Objective" &&
            comment.reletedObjectId === currentObjectiveId) ||
          (comment.reletedObjectType === "KeyResult" &&
            comment.reletedObjectId === currentKeyResultId)
      );
      setComments(relatedComments);
    } catch (error) {}
  };

  const handleUpdate = async (
    commentId,
    updatedContent,
    mentionedUserIds,
    attachedFiles,
    parentCommentId
  ) => {
    try {
      await updateComment(
        commentId,
        currentObjectiveId,
        currentKeyResultId,
        updatedContent,
        mentionedUserIds || [],
        attachedFiles || "",
        parentCommentId
      );
      await fetchComments();
    } catch (error) {}
  };

  const handleDelete = async (commentId) => {
    try {
      await deleteComment(commentId);
      await fetchComments();
    } catch (error) {}
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 3:
        return green[500];
      case 2:
        return orange[500];
      case 1:
        return red[500];
      default:
        return "inherit";
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return t("okrStatus.1");
      case 2:
        return t("okrStatus.2");
      case 3:
        return t("okrStatus.3");
      default:
        return "";
    }
  };

  const getAvatarUrl = (name) => {
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("");
    return `https://ui-avatars.com/api/?name=${initials}&background=random&color=fff`;
  };

  const filteredObjectives = Array.isArray(objectives)
    ? objectives.filter((obj) =>
        (obj.title || "")
          .toLowerCase()
          .includes((searchTerm || "").toLowerCase())
      )
    : [];

  const filteredKeyResults = Array.isArray(keyResults)
    ? keyResults.filter((kr) =>
        filterPriority ? (kr.priority || "") === filterPriority : true
      )
    : [];

  const sortedObjectives = filteredObjectives.sort((a, b) => {
    const aValue = a[sortCriteria] || "";
    const bValue = b[sortCriteria] || "";
    if (sortCriteria === "title" || sortCriteria === "status") {
      return String(aValue).localeCompare(String(bValue));
    }
    return 0;
  });

  const sortedKeyResults = filteredKeyResults.sort((a, b) => {
    const aValue = a[sortKeyCriteria] || "";
    const bValue = b[sortKeyCriteria] || "";
    if (
      sortKeyCriteria === "title" ||
      sortKeyCriteria === "priority" ||
      sortKeyCriteria === "status"
    ) {
      return String(aValue).localeCompare(String(bValue));
    }
    return 0;
  });

  const handleCellClick = (rowId, columnName, currentValue) => {
    if (editingCell?.rowId !== rowId) {
      setEditingCell({ rowId, columnName });
    } else {
      setEditingCell((prevState) => ({
        ...prevState,
        columnName,
      }));
    }
    setEditingObjective({});
    setCellValue(currentValue);
    setCellErrors({});
  };

  const handleCellChange = (event) => {
    const value = event.target.value;
    setCellValue(value);
    const errors = validateCell(editingCell.columnName, value);
    setCellErrors(errors);
  };

  const handleSliderChange = (event, newValue) => {
    setCellValue(newValue);
    const updatedKeyResults = unsavedKeyResults.map((kr) => {
      if (kr.keyResultId === editingCell.rowId) {
        let newStatus;
        if (newValue === 100) {
          newStatus = 3;
        } else if (newValue > 0) {
          newStatus = 2;
        } else {
          newStatus = 1;
        }
        return { ...kr, status: newStatus };
      }
      return kr;
    });
    setUnsavedKeyResults(updatedKeyResults);
  };

  const handleDeleteTempKeyResults = (rowId) => {
    const index = tempKeyResults.findIndex((kr) => kr.keyResultId === rowId);

    if (index !== -1) {
      const updatedKeyResults = tempKeyResults.filter((_, i) => i !== index);

      setTempKeyResults(updatedKeyResults);
    } else {
      console.error(`Key result with ID ${rowId} not found.`);
    }
  };

  const saveCellChangeToState = (rowId, columnName) => {
    const errors = validateCell(columnName, cellValue);
    if (Object.keys(errors).length > 0) {
      setCellErrors(errors);
    } else {
      const updatedKeyResult =
        keyResults.find((kr) => kr.keyResultId === rowId) ||
        tempKeyResults.find((kr) => kr.keyResultId === rowId);
      if (updatedKeyResult) {
        updatedKeyResult[columnName] = cellValue;

        if (columnName === "currentValue") {
          if (cellValue === 100) {
            updatedKeyResult.status = 3;
          } else if (cellValue > 0) {
            updatedKeyResult.status = 2;
          } else {
            updatedKeyResult.status = 1;
          }
        }

        if (tempKeyResults.includes(updatedKeyResult)) {
          setTempKeyResults([...tempKeyResults]);
        } else {
          setUnsavedKeyResults([
            ...unsavedKeyResults.filter((kr) => kr.keyResultId !== rowId),
            updatedKeyResult,
          ]);
        }
      }
    }
  };

  const handleDeleteKeyResult = async (id, objectiveId) => {
    try {
      if (window.confirm(t("okr.del_con"))) {
        await deleteKeyResult(id);
        toast.success(t("okr.toast_del_kr_s"));
        await updateObjectiveStatus(objectiveId);
      }
    } catch (err) {
      toast.error(t("okr.toast_del_kr_f"));
    }
  };

  const handleDeleteObjective = async (objectiveId) => {
    try {
      if (window.confirm(t("okr.del_con"))) {
        await deleteObjective(objectiveId);
        toast.success(t("okr.toast_del_o_s"));
        setUnsavedObjectives((prevUnsavedObjectives) =>
          prevUnsavedObjectives.filter((obj) => obj.objectiveId !== objectiveId)
        );
      }
    } catch (err) {
      toast.error(t("okr.toast_del_o_f"));
    }
  };

  const handleAddKeyResult = (objectiveId, isApproved) => {
    if (!isApproved) {
      toast.warn(t("okr.toast_add_kr_wr"));
      return;
    }
    const newKeyResult = {
      objectiveId,
      keyResultId: `temp-${Date.now()}`,
      title: "New Key Result",
      assign: "",
      priority: "Low",
      status: 1,
      targetValue: 100.0,
      currentValue: 0.0,
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString(),
      estimateManDay: 0,
      actualManDay: 0,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      notes: "",
      attachedFiles: "",
    };
    setTempKeyResults([...tempKeyResults, newKeyResult]);
  };

  const handleObjectiveCellClick = (
    objectiveId,
    currentAttribute,
    currentValue
  ) => {
    if (setEditingObjective?.objectiveId !== objectiveId) {
      setEditingObjective({ objectiveId, currentAttribute });
    } else {
      setEditingObjective((prevState) => ({
        ...prevState,
        currentAttribute,
      }));
    }
    setObjectiveValue((prevValues) => ({
      ...prevValues,
      original: {
        ...prevValues.original,
        [currentAttribute]: currentValue || "", // Ensure empty strings are stored
      },
      [currentAttribute]: currentValue,
    }));
    setEditingCell({});
    setObjectiveErrors({});
  };

  // Handle changes to the objective fields (title, description, dates, etc.)
  const handleObjectiveCellChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = typeof value === "string" ? value.trim() : value; // Trim only if it's a string

    setObjectiveValue((prevValues) => {
      // Update the current value in the objectiveValue state
      const updatedValues = {
        ...prevValues,
        [name]: value,
      };
      // Handle the case where the value is empty and needs to be reverted
      if (!trimmedValue) {
        updatedValues[name] = prevValues.original[name] || "";
      }

      // Optimistically update the objective in the UI
      setObjectives((prevObjectives) =>
        prevObjectives.map((obj) =>
          obj.objectiveId === editingObjective.objectiveId
            ? { ...obj, [name]: updatedValues[name] }
            : obj
        )
      );

      return updatedValues;
    });
  };

  // Handle date changes for objectives
  const handleObjectiveDateChange = (name, date) => {
    setObjectiveValue({
      ...objectiveValue,
      [name]: date,
    });

    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.objectiveId === editingObjective.objectiveId
          ? { ...obj, [name]: date }
          : obj
      )
    );
  };
  const handleKeyResultDateChange = (rowId, name, date) => {
    const updatedKeyResult =
      keyResults.find((kr) => kr.keyResultId === rowId) ||
      tempKeyResults.find((kr) => kr.keyResultId === rowId);

    if (updatedKeyResult) {
      updatedKeyResult[name] = date;

      if (tempKeyResults.includes(updatedKeyResult)) {
        setTempKeyResults([...tempKeyResults]);
      } else {
        setUnsavedKeyResults([
          ...unsavedKeyResults.filter((kr) => kr.keyResultId !== rowId),
          updatedKeyResult,
        ]);
      }
    }
  };

  const saveObjectiveCellChangeToState = (objectiveId) => {
    const errors = validateObjective(objectiveValue);
    if (Object.keys(errors).length > 0) {
      setObjectiveErrors(errors);
      return;
    }

    const updatedObjective = objectives.find(
      (obj) => obj.objectiveId === objectiveId
    );

    if (updatedObjective) {
      const objectiveUpdatePayload = { ...updatedObjective, ...objectiveValue };

      // If the title or description is empty or null, revert to original values
      const titleValue = objectiveValue.title;
      const descriptionValue = objectiveValue.description;
      if (
        !titleValue ||
        (typeof titleValue === "string" && titleValue.trim() === "")
      ) {
        objectiveUpdatePayload.title = updatedObjective.title;
      }

      if (
        !descriptionValue ||
        (typeof descriptionValue === "string" && descriptionValue.trim() === "")
      ) {
        objectiveUpdatePayload.description = updatedObjective.description;
      }

      setObjectives((prevObjectives) =>
        prevObjectives.map((obj) =>
          obj.objectiveId === objectiveId ? objectiveUpdatePayload : obj
        )
      );
      setObjectiveErrors({});
    }
  };

  const handleObjectiveSaveClick = async (objectiveId) => {
    try {
      const objectiveToSave = {
        ...objectives.find((obj) => obj.objectiveId === objectiveId),
        ...objectiveValue,
      };
      const errors = validateObjective(objectiveToSave);
      if (Object.keys(errors).length > 0) {
        setObjectiveErrors(errors);
        toast.error(Object.values(errors).join(". "));
        return;
      }
      await updateObjective(objectiveToSave);
      toast.success(t("okr.toast_up_o_s"));
      setUnsavedObjectives(
        unsavedObjectives.filter((o) => o.objectiveId !== objectiveId)
      );
      setEditingObjective({});
    } catch (error) {
      toast.error(t("okr.toast_up_o_f"));
    }
  };

  // Render the objective cell content
  const renderObjectiveCellContent = (objective, attribute) => {
    // const style = { wordBreak: "break-word" };
    const style = {
      whiteSpace: "nowrap", // Ensure text does not wrap
      overflow: "hidden", // Hide overflow content
      textOverflow: "ellipsis", // Show ellipsis if content is too long
      maxWidth: "150px", // Set a maximum width for the cell
      display: "inline-block", // Ensure it behaves like a block element
      cursor: "pointer",
    };
    if (
      editingObjective.objectiveId === objective.objectiveId &&
      editingObjective.currentAttribute === attribute
    ) {
      if (attribute === "startDate" || attribute === "endDate") {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              value={
                objectiveValue[attribute]
                  ? new Date(objectiveValue[attribute])
                  : null
              }
              onChange={(date) => handleObjectiveDateChange(attribute, date)}
              onBlur={() =>
                saveObjectiveCellChangeToState(objective.objectiveId)
              }
              minDateTime={
                attribute === "endDate" && objective.startDate
                  ? new Date(objective.startDate)
                  : null
              }
              maxDateTime={
                attribute === "startDate" && objective.endDate
                  ? new Date(objective.endDate)
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  error={!!objectiveErrors[attribute]}
                  helperText={objectiveErrors[attribute]}
                />
              )}
            />
          </LocalizationProvider>
        );
      } else if (attribute === "status") {
        return (
          <Select
            name={attribute}
            value={
              objectiveValue[attribute] !== undefined
                ? objectiveValue[attribute]
                : 1
            }
            onChange={handleObjectiveCellChange}
            onBlur={() => saveObjectiveCellChangeToState(objective.objectiveId)}
            autoFocus
            error={!!objectiveErrors[attribute]}
            fullWidth
          >
            <MenuItem value={1}>{t("okrStatus.1")}</MenuItem>
            <MenuItem value={2}>{t("okrStatus.2")}</MenuItem>
            <MenuItem value={3}>{t("okrStatus.3")}</MenuItem>
          </Select>
        );
      } else {
        return (
          <TextField
            name={attribute}
            value={
              objectiveValue[attribute] !== undefined
                ? objectiveValue[attribute]
                : ""
            }
            onChange={handleObjectiveCellChange}
            onBlur={() => {
              if (!objectiveValue[attribute].trim()) {
                // Revert to the original value if input is empty or whitespace
                setObjectiveValue((prevValues) => ({
                  ...prevValues,
                  [attribute]: prevValues.original[attribute] || "",
                }));
              } else {
                saveObjectiveCellChangeToState(objective.objectiveId);
              }
              setEditingObjective({
                objectiveId: null,
                currentAttribute: null,
              });
            }}
            autoFocus
            error={!!objectiveErrors[attribute]}
            helperText={objectiveErrors[attribute]}
          />
        );
      }
    }

    // Non-editing mode
    if (attribute === "startDate" || attribute === "endDate") {
      const dateValue = objective[attribute]
        ? new Date(objective[attribute])
        : null;
      const isValidDate = dateValue && !isNaN(dateValue);
      return (
        <Typography
          onClick={() =>
            handleObjectiveCellClick(
              objective.objectiveId,
              attribute,
              objective[attribute]
            )
          }
          component="div"
        >
          {isValidDate
            ? format(new Date(dateValue), "MM/dd/yyyy HH:mm:ss")
            : ""}
        </Typography>
      );
    } else if (attribute === "status") {
      return (
        <Box
          sx={{
            backgroundColor: getStatusColor(objective[attribute]),
            color: "white",
            borderRadius: 1,
            padding: "4px 8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            handleObjectiveCellClick(
              objective.objectiveId,
              attribute,
              objective[attribute]
            )
          }
        >
          {getStatusText(objective[attribute])}
        </Box>
      );
    }
    return (
      <Tooltip
        title={
          <Typography style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
            {objective[attribute] || ""}
          </Typography>
        }
        arrow
      >
        <Typography
          onClick={() =>
            handleObjectiveCellClick(
              objective.objectiveId,
              attribute,
              objective[attribute]
            )
          }
          component="div"
          style={style}
        >
          {objective[attribute]}
        </Typography>
      </Tooltip>
    );
    // return (
    //   <Typography
    //     onClick={() =>
    //       handleObjectiveCellClick(
    //         objective.objectiveId,
    //         attribute,
    //         objective[attribute]
    //       )
    //     }
    //     component="div"
    //     style={style}
    //   >
    //     {objective[attribute]}
    //   </Typography>
    // );
  };

  const renderCellContent = (row, columnName, objective) => {
    //const style = { wordBreak: "break-word" };
    const style = {
      whiteSpace: "nowrap", // Ensure text does not wrap
      overflow: "hidden", // Hide overflow content
      textOverflow: "ellipsis", // Show ellipsis if content is too long
      maxWidth: "150px", // Set a maximum width for the cell
      display: "inline-block", // Ensure it behaves like a block element
      cursor: "pointer",
    };

    // Get the date values from the objective if available
    const startDateValue = row.startDate ? row.startDate : objective.startDate;
    const endDateValue = row.endDate ? row.endDate : objective.endDate;

    if (
      editingCell &&
      editingCell.rowId === row.keyResultId &&
      editingCell.columnName === columnName
    ) {
      if (columnName === "status") {
        return (
          <Select
            value={cellValue}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            autoFocus
            error={!!cellErrors[columnName] ? true : undefined}
          >
            <MenuItem value={1}>{t("okrStatus.1")}</MenuItem>
            <MenuItem value={2}>{t("okrStatus.2")}</MenuItem>
            <MenuItem value={3}>{t("okrStatus.3")}</MenuItem>
            <MenuItem value={4}>{t("okrStatus.4")}</MenuItem>
          </Select>
        );
      } else if (columnName === "priority") {
        return (
          <Select
            value={cellValue}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            autoFocus
            fullWidth
            error={!!cellErrors[columnName] ? true : undefined}
          >
            <MenuItem value="High">{t("okr.filter_2")}</MenuItem>
            <MenuItem value="Medium">{t("okr.filter_3")}</MenuItem>
            <MenuItem value="Low">{t("okr.filter_4")}</MenuItem>
          </Select>
        );
      } else if (columnName === "assign") {
        return (
          <Select
            value={cellValue || ""}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            fullWidth
            autoFocus
            error={!!cellErrors[columnName] ? true : undefined}
          >
            <MenuItem value="">{t("okr.assign_default")}</MenuItem>
            {teamMembers.map((member) => (
              <MenuItem key={member.userId} value={member.userId}>
                {member.username !== ""
                  ? member.username
                  : member.email.split("@")[0]}
              </MenuItem>
            ))}
          </Select>
        );
      } else if (columnName === "currentValue") {
        return (
          <Slider
            value={typeof cellValue === "number" ? cellValue : 0}
            onChange={handleSliderChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            aria-labelledby="continuous-slider"
            min={0}
            max={row.targetValue}
          />
        );
      } else if (columnName === "startDate" || columnName === "endDate") {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              value={
                columnName === "startDate"
                  ? row.startDate
                    ? new Date(row.startDate)
                    : startDateValue
                  : row.endDate
                  ? new Date(row.endDate)
                  : endDateValue
              }
              onChange={(date) =>
                handleKeyResultDateChange(row.keyResultId, columnName, date)
              }
              onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
              minDateTime={
                columnName === "endDate"
                  ? new Date(startDateValue)
                  : new Date(objective.startDate)
              }
              maxDateTime={new Date(objective.endDate)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  error={!!cellErrors[columnName]}
                  helperText={cellErrors[columnName]}
                />
              )}
            />
          </LocalizationProvider>
        );
      } else if (
        columnName === "estimateManDay" ||
        columnName === "actualManDay"
      ) {
        return (
          <TextField
            value={cellValue !== undefined ? cellValue : ""}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            autoFocus
            error={!!cellErrors[columnName] ? true : undefined}
            helperText={cellErrors[columnName]}
            type="number"
          />
        );
      } else if (columnName === "attachedFiles") {
        return (
          <TextField
            value={cellValue}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            autoFocus
            error={!!cellErrors[columnName] ? true : undefined}
            helperText={cellErrors[columnName]}
          />
        );
      } else {
        return (
          <TextField
            value={cellValue}
            onChange={handleCellChange}
            onBlur={() => saveCellChangeToState(row.keyResultId, columnName)}
            autoFocus
            error={!!cellErrors[columnName] ? true : undefined}
            helperText={cellErrors[columnName]}
          />
        );
      }
    }

    if (columnName === "status") {
      return (
        <Box
          sx={{
            backgroundColor: getStatusColor(row[columnName]),
            color: "white",
            borderRadius: 1,
            padding: "4px 8px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
        >
          {getStatusText(row[columnName])}
        </Box>
      );
    }

    if (columnName === "assign") {
      let assignedUser = teamMembers.find(
        (member) => member.userId === row[columnName]
      );
      if (!assignedUser && teamMembers.length > 0) {
        assignedUser = teamMembers[0];
        row[columnName] = assignedUser.userId;
      }
      const displayName =
        assignedUser && assignedUser.username !== ""
          ? assignedUser.username
          : assignedUser.email.split("@")[0];

      return (
        <Typography
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
          component="div"
          style={style}
        >
          {displayName}
        </Typography>
      );
    }

    if (columnName === "attachedFiles") {
      return (
        <div
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
        >
          {row[columnName] &&
            row[columnName].split(",").map((file, index) => (
              <div key={index}>
                <a href={file} target="_blank" rel="noopener noreferrer">
                  File {index + 1}
                </a>
              </div>
            ))}
          <IconButton color="primary" component="span">
            <CloudUploadIcon />
          </IconButton>
        </div>
      );
    }

    if (columnName === "startDate" || columnName === "endDate") {
      const dateValue =
        columnName === "startDate" ? row.startDate : row.endDate;
      const isValidDate = dateValue && !isNaN(new Date(dateValue).getTime());
      return (
        <Typography
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
          component="div"
        >
          {isValidDate
            ? format(new Date(dateValue), "MM/dd/yyyy HH:mm:ss")
            : ""}
        </Typography>
      );
    }

    if (columnName === "estimateManDay" || columnName === "actualManDay") {
      return (
        <Typography
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
          component="div"
        >
          {row[columnName] || 0}
        </Typography>
      );
    }

    return (
      <Tooltip
        title={
          <Typography style={{ whiteSpace: "normal", wordBreak: "break-word" }}>
            {row[columnName] || ""}
          </Typography>
        }
        arrow
      >
        <Typography
          onClick={() =>
            handleCellClick(row.keyResultId, columnName, row[columnName])
          }
          component="div"
          style={style}
        >
          {row[columnName] === "" && columnName === "notes"
            ? "Add Notes"
            : row[columnName]}
        </Typography>
      </Tooltip>
    );
    // return (
    //   <Typography
    //     onClick={() =>
    //       handleCellClick(row.keyResultId, columnName, row[columnName])
    //     }
    //     component="div"
    //     style={style}
    //   >
    //     {row[columnName] === "" && columnName === "notes"
    //       ? "Add Notes"
    //       : row[columnName]}
    //   </Typography>
    // );
  };

  const handleExpandClick = (objectiveId) => {
    setEditingCell({});
    setEditingObjective({});
    setExpandedObjectiveId(
      expandedObjectiveId === objectiveId ? null : objectiveId
    );
  };

  const saveTempKeyResult = async (keyResult) => {
    try {
      await addKeyResult(keyResult);
      toast.success(t("okr.toast_add_kr_s"));
      setTempKeyResults(tempKeyResults.filter((kr) => kr !== keyResult));
      await updateObjectiveStatus(keyResult.objectiveId);
    } catch (err) {
      toast.error(t("okr.toast_add_kr_f"));
    }
  };

  const handleKeyResultSaveClick = async (keyResultId) => {
    const tempKeyResult = tempKeyResults.find(
      (kr) => kr.keyResultId === keyResultId
    );
    const keyResultToSave =
      tempKeyResult ||
      unsavedKeyResults.find((kr) => kr.keyResultId === keyResultId);

    if (keyResultToSave) {
      const fieldsToValidate = [
        "title",
        "startDate",
        "endDate",
        "estimateManDay",
        "actualManDay",
      ];
      let hasErrors = false;
      let allErrors = [];

      for (const field of fieldsToValidate) {
        const value = keyResultToSave[field];
        const errors = validateCell(field, value, keyResultToSave);

        if (Object.keys(errors).length > 0) {
          setCellErrors((prevErrors) => ({ ...prevErrors, ...errors }));
          hasErrors = true;
          if (errors[field]) {
            allErrors.push(errors[field]); // Collect non-empty error messages
          }
        }
      }

      if (hasErrors) {
        toast.error(allErrors.join(". ")); // Join messages with a period and space
        return;
      }

      try {
        if (tempKeyResult) {
          await saveTempKeyResult(tempKeyResult);
        } else {
          await updateKeyResult(keyResultToSave);
          toast.success(t("okr.toast_up_kr_s"));
          setUnsavedKeyResults(
            unsavedKeyResults.filter((kr) => kr.keyResultId !== keyResultId)
          );
          await updateObjectiveStatus(keyResultToSave.objectiveId);
        }
      } catch (err) {
        toast.error(t("okr.toast_save_kr_f"));
      } finally {
        setEditingCell({});
      }
    }
  };

  const handleRefresh = () => {
    if (window.confirm(t("okr.rf_con"))) {
      fetchObjectives();
      setTempKeyResults([]);
      setExpandedObjectiveId(null);
      setEditingCell({})
      setEditingObjective({})
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setObjectiveDialogOpen(true)}
          style={{ flex: "2", marginRight: "16px" }}
          disabled={!teamId}
        >
          {t("okr.add_O_Button")}
        </Button>

        <TextField
          label={t("okr.search")}
          variant="outlined"
          size="small"
          style={{ flex: "4", marginRight: "16px" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FormControl style={{ flex: "2" }} size="small">
          <InputLabel>{t("okr.sort_By")}</InputLabel>
          <Select
            value={sortCriteria}
            onChange={(e) => setSortCriteria(e.target.value)}
            label={t("okr.sort_By")}
          >
            <MenuItem value="title">{t("okr.sort_1")}</MenuItem>
            <MenuItem value="status">{t("okr.sort_3")}</MenuItem>
          </Select>
        </FormControl>
        <IconButton color="primary" onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("okr.title")}</TableCell>
              <TableCell>{t("okr.description")}</TableCell>
              <TableCell>{t("okr.start_Date")}</TableCell>
              <TableCell>{t("okr.end_Date")}</TableCell>
              <TableCell>{t("okr.status")}</TableCell>
              <TableCell>{t("okr.approved")}</TableCell>
              <TableCell>{t("okr.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedObjectives.map((objective) => (
              <React.Fragment
                key={`objective-fragment-${objective.objectiveId}`}
              >
                <TableRow key={`objective-${objective.objectiveId}`}>
                  <TableCell
                    sx={{ flex: 1, width: "12%", textAlign: "center" }}
                  >
                    <IconButton
                      onClick={() => handleExpandClick(objective.objectiveId)}
                    >
                      {expandedObjectiveId === objective.objectiveId ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ flex: 1, width: "12%" }}>
                    {renderObjectiveCellContent(objective, "title")}
                  </TableCell>
                  <TableCell sx={{ flex: 1, width: "12%" }}>
                    {renderObjectiveCellContent(objective, "description")}
                  </TableCell>
                  <TableCell sx={{ flex: 1, width: "12%" }}>
                    {renderObjectiveCellContent(objective, "startDate")}
                  </TableCell>
                  <TableCell sx={{ flex: 1, width: "12%" }}>
                    {renderObjectiveCellContent(objective, "endDate")}
                  </TableCell>
                  <TableCell sx={{ flex: 1, width: "12%" }}>
                    {renderObjectiveCellContent(objective, "status")}
                  </TableCell>
                  <TableCell
                    sx={{ flex: 1, width: "12%", textAlign: "center" }}
                  >
                    <Checkbox
                      checked={objective.isApprove}
                      onChange={(e) =>
                        handleCheckboxChange(e, objective.objectiveId)
                      }
                      disabled={!isMentor}
                    />
                  </TableCell>
                  <TableCell
                    sx={{ flex: 1, width: "12%", textAlign: "center" }}
                  >
                    <IconButton
                      color="primary"
                      disabled={
                        editingObjective?.objectiveId !== objective.objectiveId
                      }
                      onClick={() =>
                        handleObjectiveSaveClick(objective.objectiveId)
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() =>
                        handleDeleteObjective(objective.objectiveId)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow key={`collapse-${objective.objectiveId}`}>
                  <TableCell colSpan={8} sx={{ maxWidth: 100 }}>
                    <Collapse
                      in={expandedObjectiveId === objective.objectiveId}
                      timeout="auto"
                      unmountOnExit
                      sx={{ width: "100%" }}
                    >
                      <Box margin={2} gap={2} display={"flex"}>
                        <FormControl
                          style={{
                            flex: "2",
                            width: 300,
                          }}
                        >
                          <InputLabel>{t("okr.filter_Priority")}</InputLabel>
                          <Select
                            value={filterPriority}
                            onChange={(e) => setFilterPriority(e.target.value)}
                            label={t("okr.filter_Priority")}
                          >
                            <MenuItem value="">{t("okr.filter_1")}</MenuItem>
                            <MenuItem value="High">
                              {t("okr.filter_2")}
                            </MenuItem>
                            <MenuItem value="Medium">
                              {t("okr.filter_3")}
                            </MenuItem>
                            <MenuItem value="Low">{t("okr.filter_4")}</MenuItem>
                          </Select>
                        </FormControl>
                        <FormControl style={{ flex: "2" }}>
                          <InputLabel>{t("okr.sort_By")}</InputLabel>
                          <Select
                            value={sortCriteria}
                            onChange={(e) => setSortKeyCriteria(e.target.value)}
                            label={t("okr.sort_By")}
                          >
                            <MenuItem value="title">{t("okr.sort_1")}</MenuItem>
                            <MenuItem value="priority">
                              {t("okr.sort_2")}
                            </MenuItem>
                            <MenuItem value="status">
                              {t("okr.sort_3")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Button
                          key={`add-keyResult-${objective.objectiveId}`}
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            handleAddKeyResult(
                              objective.objectiveId,
                              objective.isApprove
                            )
                          }
                          disabled={!objective.isApprove} // Disable the button if not approved
                        >
                          {t("okr.add_kr_Button")}
                        </Button>
                      </Box>
                      <Box margin={1} sx={{ overflowX: "auto" }}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ minWidth: 300 }}>
                                {t("okr.title")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 300 }}>
                                {t("okr.assign_to")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 150 }}>
                                {t("okr.priority")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 150 }}>
                                {t("okr.status")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 100 }}>
                                {t("okr.progress")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 270 }}>
                                {t("okr.start_Date")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 270 }}>
                                {t("okr.end_Date")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 100 }}>
                                {t("okr.estimate_Man_Day")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 100 }}>
                                {t("okr.actual_Man_Day")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 150 }}>
                                {t("okr.difference")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 100 }}>
                                {t("okr.notes")}
                              </TableCell>
                              <TableCell sx={{ minWidth: 150 }}>
                                {t("okr.attached_Files")}
                              </TableCell>
                              <TableCell>{t("okr.comments")}</TableCell>
                              <TableCell>{t("okr.actions")}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sortedKeyResults
                              .filter(
                                (kr) => kr.objectiveId === objective.objectiveId
                              )
                              .map((keyResult) => (
                                <TableRow
                                  key={`keyResult-${keyResult.keyResultId}`}
                                >
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "title")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "assign")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "priority")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "status")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "currentValue"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "startDate",
                                      objective
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "endDate",
                                      objective
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "estimateManDay"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "actualManDay"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {keyResult.estimateManDay -
                                      keyResult.actualManDay}{" "}
                                    Days
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "notes")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "attachedFiles"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "8%", textAlign: "center" }}
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleCommentsClick(
                                          keyResult.keyResultId,
                                          objective.objectiveId
                                        )
                                      }
                                    >
                                      <CommentIcon />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "8%", textAlign: "center" }}
                                  >
                                    <IconButton
                                      color="primary"
                                      disabled={
                                        editingCell?.rowId !==
                                        keyResult.keyResultId
                                      }
                                      onClick={() =>
                                        handleKeyResultSaveClick(
                                          keyResult.keyResultId
                                        )
                                      }
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      onClick={() =>
                                        handleDeleteKeyResult(
                                          keyResult.keyResultId,
                                          keyResult.objectiveId
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            {tempKeyResults
                              .filter(
                                (kr) => kr.objectiveId === objective.objectiveId
                              )
                              .map((keyResult, index) => (
                                <TableRow
                                  key={`temp-${keyResult.keyResultId}-${index}`}
                                  style={{ backgroundColor: "lightgray" }}
                                >
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "title")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "assign")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "priority")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "status")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "currentValue"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "startDate",
                                      objective
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "endDate",
                                      objective
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "estimateManDay"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "actualManDay"
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {keyResult.estimateManDay -
                                      keyResult.actualManDay}{" "}
                                    Days
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(keyResult, "notes")}
                                  </TableCell>
                                  <TableCell sx={{ width: "8%" }}>
                                    {renderCellContent(
                                      keyResult,
                                      "attachedFiles"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "8%", textAlign: "center" }}
                                  >
                                    <IconButton color="primary" disabled>
                                      <CommentIcon />
                                    </IconButton>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "8%", textAlign: "center" }}
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        handleKeyResultSaveClick(
                                          keyResult.keyResultId
                                        )
                                      }
                                    >
                                      <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      onClick={() =>
                                        handleDeleteTempKeyResults(
                                          keyResult.keyResultId
                                        )
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ObjectiveForm
        open={objectiveDialogOpen}
        handleClose={() => setObjectiveDialogOpen(false)}
        onSave={handleObjectiveSave}
        teamId={teamId}
      />
      <CommentReplyDialog
        open={commentReplyDialogOpen}
        onClose={handleCloseCommentDialog}
        comments={comments}
        onAddComment={handleAddComment}
        onUpdateComment={handleUpdate}
        onDeleteComment={handleDelete}
        onUpdateReply={handleUpdate}
        onDeleteReply={handleDelete}
      />
    </div>
  );
};

export default MainTableView;
