import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog, DialogTitle, DialogContent, TextField, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton, Grid
} from '@mui/material';
import { OKRContext } from '../../context/OKRContext';
import apiAuth from '../../customs/axiosCustom'; // Adjust the path as needed
import { useAuth } from "../../customs/authService";
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { toast } from 'react-toastify';

const TeamManagementDialog = ({ open, onClose }) => {
  const { teamMembers, teamId, fetchTeamMembers } = useContext(OKRContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { t, auth } = useAuth();
  const isMentor = auth.user.roles && auth.user.roles.includes('Mentor');

  useEffect(() => {
    if (open && teamId) {
      fetchAllUsers();
      fetchTeamMembers();
    }
  }, [open, teamId]);

  useEffect(() => {
    // Filter allUsers to exclude teamMembers
    const teamMemberIds = new Set((teamMembers || []).map(member => member.userId));
    setFilteredUsers(
      (allUsers || []).filter(user => !teamMemberIds.has(user.id))
    );
  }, [allUsers, teamMembers]);

  const fetchAllUsers = async () => {
    try {
      const response = await apiAuth.get('/OKR/GetAllUsers'); // Adjust the endpoint as needed
      if (response.data && Array.isArray(response.data.users)) {
        // Map users to { email, id, username } format
        setAllUsers(response.data.users.map(user => ({
          email: user.email,
          id: user.id,
          username: user.username // Ensure this matches your user object
        })));
      }
    } catch (error) {
      console.error('Error fetching all users:', error);
    }
  };

  const handleInviteMember = async (email) => {
    if (!email) {
      console.error('Email cannot be empty');
      return;
    }
    try {
      await apiAuth.post(`/OKR/InviteMember?currentTeamId=${teamId}`, { email });
      
      // Update allUsers state manually
      setAllUsers(prevAllUsers => prevAllUsers.filter(user => user.email !== email));

      fetchTeamMembers(); // Refresh the team members list
      setSearchTerm(''); // Clear the input field
      toast.success('Member invited successfully!');
    } catch (error) {
      toast.error('Error inviting new member');
    }
  };

  const handleRemoveMember = async (userId) => {
    try {
      await apiAuth.delete(`/OKR/RemoveMember`, { data: { teamId, userId } });

      // Fetch the updated list of users from the API
      fetchAllUsers(); // This will automatically update filteredUsers

      fetchTeamMembers(); // Refresh the team members list
      toast.success('Member removed successfully!');
    } catch (error) {
      toast.error('Error removing member');
    }
  };

  const handleSearchChange = (event, value) => {
    setSearchTerm(value?.email || value || ''); // Ensure searchTerm is a string
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("okr.t_title")}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isMentor && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={10}>
              <Autocomplete
                freeSolo
                options={filteredUsers}
                value={{ email: searchTerm }}
                getOptionLabel={(option) => option.email || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("okr.t_search")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                )}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color="primary" onClick={() => handleInviteMember(searchTerm)}>
                <PersonAddIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <List>
          {teamMembers.map((member) => (
            <ListItem key={member.userId}>
              <ListItemAvatar>
                <Avatar>{member.username?.charAt(0) || member.email.split('@')[0].charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={member.username || member.email.split('@')[0]} />
              {isMentor && (
                <IconButton onClick={() => handleRemoveMember(member.userId)} >
                  <RemoveIcon />
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default TeamManagementDialog;
