import axios from "axios";
import { toast } from "react-toastify";
import { createContext, useState, useEffect, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { showLoading, hideLoading } from "../components/Layout/LoadingScreen";
import { useTranslation } from "react-i18next";
import { menuItems } from "../assets/configs/routes";
import Unauthorized from "../components/Authenticate/Unauthorized";
import Forbidden from "../components/Authenticate/Forbidden";

const AuthContext = createContext();
const api = process.env.REACT_APP_BE_API;

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [isIntern, setIsIntern] = useState(null);
  const [drawerItems, setDrawerItems] = useState(null);
  const [currentSemester, setCurrentSemester] = useState({});
  const { t, i18n } = useTranslation();
  const navigator = useNavigate();

  function setCookie(name, value, exp) {
    const date = new Date(exp * 1000); // Convert seconds to milliseconds
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  }

  function deleteCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999; path=/";
  }

  // Hàm để lấy cookie
  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Hàm để lưu JWT vào cookie với thời gian hết hạn từ JWT
  function saveJWTToCookie(jwtToken) {
    const decodedToken = jwtDecode(jwtToken);
    if (decodedToken && decodedToken.exp) {
      setCookie("jwtToken", jwtToken, decodedToken.exp);
    } else {
      console.error("Invalid JWT token or missing expiration");
    }
  }

  const checkLoggedIn = async () => {
    const token = getCookie("jwtToken");
    if (token) {
      const decoded = jwtDecode(token);
      setDrawerItems(filterMenuItemsByRole(menuItems, decoded.roles));
      isInternCheckAfterLogin(decoded);
      setAuth({ user: decoded, token });
      const lang = localStorage.getItem("lang");
      i18n.changeLanguage(lang);
    } else {
      navigator("/login");
      setAuth({ user: null, token: null });
      setDrawerItems(null);
    }
  };

  const filterMenuItemsByRole = (menuItems, userRoles) => {
    return menuItems.filter((menuItem) => {
      if (Array.isArray(userRoles)) {
        return userRoles.some((role) => menuItem.allowedRoles.includes(role));
      } else {
        return menuItem.allowedRoles.includes(userRoles);
      }
    });
  };

  const isInternCheck = () => {
    setIsIntern(auth?.user?.roles.includes("Intern"));
  };

  const isInternCheckAfterLogin = (decode) => {
    setIsIntern(decode.roles.includes("Intern"));
  };

  const isLogged = () => {
    if (auth && auth?.user) {
      return true;
    }
    return false;
  };

  const isTesting = () => {
    if (auth && auth?.user && auth?.user?.isTestAccount === "True") {
      return true;
    }
    return false;
  };

  const isTestingAfterLogin = (decoded) => {
    if (decoded && decoded?.isTestAccount) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    isInternCheck();
  }, [isIntern]);

  useEffect(() => {
    checkLoggedIn();
    handleCurrentSemester();
  }, []);

  const logout = () => {
    deleteCookie("jwtToken");
    localStorage.removeItem("lang");
    setAuth(null);
    setDrawerItems(null);
    setIsIntern(null);
    navigator("/login");
  };

  const handleLogin = async ({ email, password }) => {
    showLoading();
    try {
      const res = await axios.post(`${api}/auth/login`, {
        username: email,
        password,
      });
      const token = res.data?.token;
      const decoded = jwtDecode(token);
      saveJWTToCookie(token);
      setDrawerItems(filterMenuItemsByRole(menuItems, decoded.roles));
      if (decoded.language) {
        localStorage.setItem("lang", decoded.language);
        i18n.changeLanguage(decoded.language);
      }
      isInternCheckAfterLogin(decoded);
      isTestingAfterLogin(decoded);
      setAuth({ user: decoded, token });
      toast.success(t("loginSuccess", { user: decoded?.email }));
      navigator("/");
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(t("validate"));
      } else {
        toast.error(t(error.response?.data.message));
      }
    } finally {
      hideLoading();
    }
  };

  const handleAdminLogin = async ({ username, password }) => {
    showLoading();
    try {
      const res = await axios.post(`${api}/auth/admin/login`, {
        username,
        password,
      });
      const token = res.data?.token;
      const decoded = jwtDecode(token);
      saveJWTToCookie(token);
      isInternCheckAfterLogin(decoded);
      isTestingAfterLogin(decoded);
      setDrawerItems(filterMenuItemsByRole(menuItems, decoded.roles));
      if (decoded.language) {
        localStorage.setItem("lang", decoded.language);
        i18n.changeLanguage(decoded.language);
      }
      setAuth({ user: decoded, token });
      toast.success(t("loginSuccess", { user: "Admin" }));
      navigator("/");
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(t("validate"));
      } else {
        toast.error(t(error.response?.data.message));
      }
    } finally {
      hideLoading();
    }
  };

  const handleGoogleLogin = async (response) => {
    showLoading();
    const tokenId = response.credential;
    try {
      const res = await axios.post(`${api}/auth/google`, { tokenId });
      const token = res.data?.token;
      const decoded = jwtDecode(token);
      saveJWTToCookie(token);
      isInternCheckAfterLogin(decoded);
      isTestingAfterLogin(decoded);
      setDrawerItems(filterMenuItemsByRole(menuItems, decoded.roles));
      if (decoded.language) {
        localStorage.setItem("lang", decoded.language);
        i18n.changeLanguage(decoded.language);
      }
      setAuth({ user: decoded, token });
      toast.success(t("loginSuccess", { user: decoded.email }));
      navigator("/");
    } catch (error) {
      toast.error(t(error.response?.data.message));
    } finally {
      hideLoading();
    }
  };

  const isAllowed = (allowedRoles) => {
    return Array.isArray(auth.user.roles)
      ? auth.user.roles.some((item) => allowedRoles.includes(item))
      : allowedRoles.includes(auth.user.roles);
  };

  const privateAuth = ({ allowedRoles, children }) => {
    if (auth?.user && auth?.user.roles) {
      return isAllowed(allowedRoles) ? (
        children
      ) : (
        <Forbidden allowedRoles={allowedRoles} />
      );
    }
    return <Unauthorized allowedRoles={allowedRoles} />;
  };

  const handleCurrentSemester = async () => {
    try {
      const response = await axios.get(`${api}/Semester/currentSemester`);
      setCurrentSemester(response.data);
    } catch (error) {
      console.error(error.response?.data);
      setCurrentSemester({});
    }
  };

  const value = {
    auth,
    handleLogin,
    handleGoogleLogin,
    handleAdminLogin,
    logout,
    t,
    i18n,
    drawerItems,
    isIntern,
    privateAuth,
    isLogged,
    isTesting,
    currentSemester,
    setCurrentSemester,
    handleCurrentSemester
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
