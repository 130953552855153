import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableSortLabel,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Checkbox,
  Typography,
} from "@mui/material";
import { useCandidates } from "../../context/CandidatesContext";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const CandidateList = () => {
  const { t } = useAuth();
  const {
    candidates = [],
    fetchCandidateCV,
    candidateCVs,
    approveOrRejectInterns,
    fetchCandidates,
    fetchUnassignedCandidates,
    unassignedCandidates = [],
    mentors = [],
    assignImportIntern,
    fetchMentors,
  } = useCandidates(); // Default to empty array
  const { auth } = useAuth();
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstName");
  const [sortDirectionUnassigned, setSortDirectionUnassigned] = useState("asc");
  const [orderByUnassigned, setOrderByUnassigned] = useState("firstName");
  const [open, setOpen] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedCandidatesAssign, setSelectedCandidatesAssign] = useState([]);

  useEffect(() => {
    try {
      showLoading();
      fetchCandidates();
      if (auth.user.roles.includes("Lab Manager")) {
        fetchUnassignedCandidates();
        fetchMentors();
      }
    } finally {
      hideLoading();
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && sortDirection === "asc";
    setOrderBy(property);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const handleSortUnassignedRequest = (property) => {
    const isAsc =
      orderByUnassigned === property && sortDirectionUnassigned === "asc";
    setOrderByUnassigned(property);
    setSortDirectionUnassigned(isAsc ? "desc" : "asc");
  };

  const handleShowCV = async (id) => {
    await fetchCandidateCV(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e, internId) => {
    assignImportIntern(internId, e.target.value);
  };

  const handleApproveOrReject = (id, approved) => {
    const internApprovals = [{ InternId: id, Approved: approved }];
    approveOrRejectInterns(internApprovals);
  };

  const handleApproveOrRejectList = (approved) => {
    if(window.confirm(t(`candidate.confirmMultiple`))) {
      const internApprovals = selectedCandidates.map((candidate) => ({
        InternId: candidate,
        Approved: approved,
      }));
      approveOrRejectInterns(internApprovals);
      setSelectedCandidates([])
    }
  };

  const filteredCandidates = candidates
    .filter(
      (candidate) =>
        candidate && // Ensure candidate is defined
        ((candidate.firstName &&
          candidate.firstName.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.lastName &&
            candidate.lastName.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.email &&
            candidate.email.toLowerCase().includes(search.toLowerCase())))
    )
    .sort((a, b) => {
      if (!a || !b) return 0; // Check for undefined candidates
      if (orderBy === "firstName") {
        return (
          (a.firstName < b.firstName ? -1 : 1) *
          (sortDirection === "asc" ? 1 : -1)
        );
      } else {
        return (
          (a.lastName < b.lastName ? -1 : 1) *
          (sortDirection === "asc" ? 1 : -1)
        );
      }
    });

  const filteredUnassignCandidates = unassignedCandidates
    .filter(
      (candidate) =>
        candidate && // Ensure candidate is defined
        ((candidate.firstName &&
          candidate.firstName.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.lastName &&
            candidate.lastName.toLowerCase().includes(search.toLowerCase())) ||
          (candidate.email &&
            candidate.email.toLowerCase().includes(search.toLowerCase())))
    )
    .sort((a, b) => {
      if (!a || !b) return 0; // Check for undefined candidates
      if (orderByUnassigned === "firstName") {
        return (
          (a.firstName < b.firstName ? -1 : 1) *
          (sortDirectionUnassigned === "asc" ? 1 : -1)
        );
      } else {
        return (
          (a.lastName < b.lastName ? -1 : 1) *
          (sortDirectionUnassigned === "asc" ? 1 : -1)
        );
      }
    });

  const handleSelectCandidate = (candidateId) => {
    setSelectedCandidates((prev) =>
      prev.includes(candidateId)
        ? prev.filter((id) => id !== candidateId)
        : [...prev, candidateId]
    );
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <TextField
          label={t("candidate.search")}
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton edge="end" aria-label="search">
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Box>
        <h2>{t(`candidate.candidateWaiting`)}</h2>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: 510, overflowY: "auto" }}
        >
          <Box sx={{ m: 3, display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{fontStyle: "italic"}}>
              {t(`candidate.multiSelect`)}
            </Typography>
            <Box>
              <Button
                sx={{ marginRight: 1 }}
                color="success"
                variant="contained"
                disabled={!(selectedCandidates.length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleApproveOrRejectList(true);
                }}
              >
                {t(`candidate.table.approve`)}
              </Button>
              <Button
                color="error"
                variant="contained"
                disabled={!(selectedCandidates.length > 0)}
                onClick={(e) => {
                  e.stopPropagation();
                  handleApproveOrRejectList(false);
                }}
              >
                {t(`candidate.table.reject`)}
              </Button>
            </Box>
          </Box>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedCandidates.length > 0 &&
                      selectedCandidates.length < filteredCandidates.length
                    }
                    checked={
                      filteredCandidates.length > 0 &&
                      selectedCandidates.length === filteredCandidates.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelectedCandidates(
                          filteredCandidates.map((candidate) => candidate.id)
                        );
                      } else {
                        setSelectedCandidates([]);
                      }
                    }}
                  />
                </TableCell>
                <TableCell width={200}>
                  <TableSortLabel
                    active={orderBy === "firstName"}
                    direction={orderBy === "firstName" ? sortDirection : "asc"}
                    onClick={() => handleSortRequest("firstName")}
                  >
                    {t(`candidate.table.firstName`)}
                  </TableSortLabel>
                </TableCell>
                <TableCell width={200}>
                  <TableSortLabel
                    active={orderBy === "lastName"}
                    direction={orderBy === "lastName" ? sortDirection : "asc"}
                    onClick={() => handleSortRequest("lastName")}
                  >
                    {t(`candidate.table.lastName`)}
                  </TableSortLabel>
                </TableCell>
                <TableCell width={300}>{t(`candidate.table.email`)}</TableCell>
                <TableCell width={200}>
                  {t(`candidate.table.labName`)}
                </TableCell>
                <TableCell width={200}>CV</TableCell>
                <TableCell width={300}>
                  {t(`candidate.table.actions`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCandidates.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{ textAlign: "center", color: "error.main" }}
                  >
                    {t(`candidate.table.noCandidate`)}
                  </TableCell>
                </TableRow>
              ) : (
                filteredCandidates.map(
                  (candidate) =>
                    candidate && (
                      <TableRow key={candidate.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedCandidates.includes(candidate.id)}
                            onChange={() => handleSelectCandidate(candidate.id)}
                          />
                        </TableCell>
                        <TableCell width={200}>{candidate.firstName}</TableCell>
                        <TableCell width={200}>{candidate.lastName}</TableCell>
                        <TableCell width={300}>{candidate.email}</TableCell>
                        <TableCell width={200}>{candidate.labName}</TableCell>
                        <TableCell width={200}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleShowCV(candidate.id)}
                          >
                            {t(`candidate.table.showCv`)}
                          </Button>
                        </TableCell>
                        <TableCell width={300}>
                          <Button
                            sx={{ marginRight: 1 }}
                            variant="outlined"
                            color="success"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleApproveOrReject(candidate.id, true);
                            }}
                          >
                            {t(`candidate.table.approve`)}
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleApproveOrReject(candidate.id, false);
                            }}
                          >
                            {t(`candidate.table.reject`)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {auth.user.roles.includes("Lab Manager") &&
        filteredUnassignCandidates.length !== 0 && (
          <Box>
            <h2>{t(`candidate.candidateAssign`)}</h2>
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 510, overflowY: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell width={200}>
                      <TableSortLabel
                        active={orderByUnassigned === "firstName"}
                        direction={
                          orderByUnassigned === "firstName"
                            ? sortDirectionUnassigned
                            : "asc"
                        }
                        onClick={() => handleSortUnassignedRequest("firstName")}
                      >
                        {t(`candidate.table.firstName`)}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width={200}>
                      <TableSortLabel
                        active={orderByUnassigned === "lastName"}
                        direction={
                          orderByUnassigned === "lastName"
                            ? sortDirectionUnassigned
                            : "asc"
                        }
                        onClick={() => handleSortUnassignedRequest("lastName")}
                      >
                        {t(`candidate.table.lastName`)}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width={300}>
                      {t(`candidate.table.email`)}
                    </TableCell>
                    <TableCell width={200}>
                      {t(`candidate.table.labName`)}
                    </TableCell>
                    <TableCell width={200}>CV</TableCell>
                    <TableCell width={300}>
                      {t(`candidate.table.assignTo`)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUnassignCandidates.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{ textAlign: "center", color: "error.main" }}
                      >
                        {t(`candidate.table.noCandidate`)}
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredUnassignCandidates.map((candidate) => (
                      <TableRow key={candidate.id}>
                        <TableCell width={200}>{candidate.firstName}</TableCell>
                        <TableCell width={200}>{candidate.lastName}</TableCell>
                        <TableCell width={300}>{candidate.email}</TableCell>
                        <TableCell width={200}>{candidate.labName}</TableCell>
                        <TableCell width={200}>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleShowCV(candidate.id)}
                          >
                            {t(`candidate.table.showCv`)}
                          </Button>
                        </TableCell>
                        <TableCell width={300}>
                          <FormControl fullWidth>
                            <InputLabel>Mentor</InputLabel>
                            <Select
                              label="Mentor"
                              value=""
                              onChange={(e) => handleChange(e, candidate.id)}
                            >
                              {mentors.map((mentor, index) => (
                                <MenuItem
                                  key={`mentor-${index}-${mentor.id}`}
                                  value={mentor.id}
                                >
                                  {mentor.name.trim()
                                    ? `${mentor.name.trim()}`
                                    : mentor.email}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      {candidateCVs && (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>{t(`candidate.table.candidateCv`)}</DialogTitle>
          <DialogContent>
            {candidateCVs && (
              <embed
                src={candidateCVs}
                width="100%"
                height="800px"
                title="Candidate CV"
              ></embed>
            )}
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default CandidateList;
